import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { pageTransition, pageVariants } from "../config/PageAnimations";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import config from "../config/Config";

const Discography = () => {
  const [apiStatus, setAPIStatus] = useState({
    error: false,
    loaded: false,
    message: "",
  });
  const [albums, setAlbums] = useState();

  useEffect(() => {
    setAPIStatus({
      error: false,
      loaded: false,
      message: "",
    });

    fetch(`${config.api_root}/albums`)
      .then((response) => response.json())
      .then(
        (result) => {
          setAlbums(result);
          setAPIStatus({
            error: false,
            loaded: true,
            message: "",
          });
        },
        (error) => {
          setAPIStatus({
            error: true,
            loaded: true,
            message: "There was an error fetching the albums: " + error,
          });
        }
      );
  }, []);

  return (
    <React.Fragment>
      <motion.div
        className="main-content-container"
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Helmet>
          <title>Discography | Clicks and Hisses</title>
        </Helmet>
        <div className="main-content-outer flex-start">
          <div className="main-content-inner">
            {apiStatus.loaded ? (
              apiStatus.error ? (
                <div className="discography-container">
                  <div className="discography-container-msg-wrap">
                    <h1>
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    </h1>
                    <div className="discography-container-msg">
                      {apiStatus.message}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="discography-container">
                  {albums.map((album) => {
                    return (
                      <div className="disc-album-container" key={album.id}>
                        <div className="disc-album-header-wrap">
                          <div
                            className="disc-album-header-img"
                            style={{
                              backgroundImage: `url("https://cdn.surlydigital.com/cah/assets/albums/${album.imageUrl}")`,
                            }}
                          ></div>
                          <div className="disc-album-screen"></div>
                          <div
                            className="disc-album-cover-img"
                            style={{
                              backgroundImage: `url("https://cdn.surlydigital.com/cah/assets/albums/${album.imageUrl}")`,
                            }}
                          ></div>
                          <div className="disc-album-header-info-wrap">
                            <div>
                              <h1 className="disc-album-name">{album.name}</h1>
                              <div className="album-attr-container">
                                {album.year && album.label && (
                                  <div className="album-attr">
                                    {album.year} &#8226; {album.label}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="track-listing-wrap">
                          {album.tracks.map((track, index) => (
                            <NavLink
                              to={`/lyrics/${track.trackId}`}
                              key={track.trackId}
                            >
                              <div className="album-track-wrap">
                                <div className="track-num">{index + 1}.</div>
                                {track.trackName}{" "}
                                {track.trackBonus && (
                                  <span className="track-bonus">*</span>
                                )}
                                {track.primaryAnnotator && (
                                  <span className="track-annotatior-wrap">
                                    ({track.primaryAnnotator.name} &#8226;{" "}
                                    {track.primaryAnnotator.year})
                                  </span>
                                )}
                              </div>
                            </NavLink>
                          ))}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )
            ) : (
              <div className="discography-container">
                <div className="discography-container-msg-wrap">
                  <div>
                    <div className="discography-container-msg">
                      Loading discography...
                    </div>
                    <FontAwesomeIcon
                      icon={faCircleNotch}
                      className="fa-spin loader"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </motion.div>
    </React.Fragment>
  );
};

export default Discography;
