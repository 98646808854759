export const pageVariants = {
  initial: {
    opacity: 0,
    y: -20,
    scale: 0.95,
  },
  in: {
    opacity: 1,
    y: 0,
    scale: 1,
  },
  out: {
    opacity: 0,
    y: 20,
    scale: 1.05,
  },
};

export const pageTransition = {
  type: "spring",
  ease: "easeOut",
  duration: 0.6,
};
