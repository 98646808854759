import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { pageTransition, pageVariants } from "../config/PageAnimations";
import {
  useHistory,
  useLocation,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";
// import Summary from "./Summary";
import Reference from "./Annotation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faCircleNotch,
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import DiscographyPanel from "./DiscographyPanel";
import { Helmet } from "react-helmet";
import config from "../config/Config";

const Lyrics = ({ ...props }) => {
  let history = useHistory();
  let location = useLocation();
  let { songID } = props.match.params;
  const [apiStatus, setAPIStatus] = useState({
    error: false,
    loaded: false,
    message: "",
  });
  const [song, setSong] = useState();
  let { match } = props;
  const [spotifyLoaded, setSpotifyLoaded] = useState(false);
  const [annotationMobDisplay, setAnnotationMobDisplay] = useState(false);
  const [discogMobDisplay, setDiscogMobDisplay] = useState(false);

  useEffect(() => {
    setAPIStatus({
      error: false,
      loaded: false,
      message: "",
    });

    fetch(`${config.api_root}/lyrics/${songID}`)
      .then((response) => response.json())
      .then(
        (result) => {
          setSong(result);
          setAPIStatus({
            error: false,
            loaded: true,
            message: "",
          });
        },
        (error) => {
          setAPIStatus({
            error: true,
            loaded: true,
            message: "There was an error fetching the song: " + error,
          });
        }
      );
  }, [songID]);

  useEffect(() => {
    // TODO: find a more elegant way to do this
    let refID = location.pathname.split("/")[3];

    if (refID !== "" && apiStatus.loaded) {
      // assumes a refID is requested and the dangerouslySetInnerHTML is done

      let markup = document.getElementById("lyrics");
      var elements = markup.getElementsByClassName("ref-anchor");

      for (var elem of elements) {
        if (elem.getAttribute("refID") === refID)
          elem.classList.add("selected");
        else
          elem.classList.contains("selected") &&
            elem.classList.remove("selected");
      }
    }

    if (refID) setAnnotationMobDisplay(true);
  }, [location, apiStatus.loaded]);

  const handleClick = (event) => {
    const el = event.target.closest(".ref-anchor");

    if (el && event.currentTarget.contains(el))
      history.push(`${match.url}/${el.getAttribute("refID")}`);
  };

  const createMarkup = (value) => {
    return { __html: value };
  };

  // Prevent body scrolling when modal is open
  useEffect(() => {
    if (annotationMobDisplay) {
      document.body.style.overflow = "hidden";
      document.body.style.touchAction = "none";
    } else {
      document.body.style.overflow = "auto";
      document.body.style.touchAction = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
      document.body.style.touchAction = "auto";
    };
  }, [annotationMobDisplay]);

  return (
    <motion.div
      className="main-content-container"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Helmet>
        <title>
          {song ? `${song.name} Lyrics` : "Lyrics"} | Clicks and Hisses
        </title>
      </Helmet>
      <div className="main-content-outer flex-start">
        <div className="main-content-inner">
          <div className="song-container">
            <div
              className={`mob-slider ${
                discogMobDisplay ? "show-panel" : "hide-panel"
              } ${!annotationMobDisplay ? "show-slider" : "hide-slider"}`}
              onClick={(e) => setDiscogMobDisplay(!discogMobDisplay)}
            >
              <FontAwesomeIcon
                icon={discogMobDisplay ? faChevronLeft : faChevronRight}
              />
            </div>
            <div
              className={`song-discography-container ${
                discogMobDisplay ? "show-panel" : "hide-panel"
              }`}
            >
              <DiscographyPanel />
            </div>
            {apiStatus.loaded ? (
              <div className="song-lyrics-container">
                {!apiStatus.error ? (
                  <React.Fragment>
                    <h1
                      className="song-title"
                      onClick={(event) => history.push(`${match.url}`)}
                    >
                      {song.name}
                    </h1>
                    {song.spotifyUri && (
                      <div
                        className={`spotify-player-wrap ${
                          spotifyLoaded ? "loaded" : ""
                        }`}
                      >
                        <div className="spotify-player-loading"></div>
                        <iframe
                          title="Spotify Player"
                          src={song.spotifyUri}
                          width="300"
                          height="80"
                          frameBorder="0"
                          allowtransparency="true"
                          allow="encrypted-media"
                          onLoad={(e) => setSpotifyLoaded(true)}
                        ></iframe>
                      </div>
                    )}
                    {!song.primaryAnnotator && (
                      <div className="annotation-soon-msg">
                        Annotations coming soon!
                      </div>
                    )}
                    <div className="song-lyrics-wrapper">
                      <div
                        id="lyrics"
                        onClick={handleClick}
                        dangerouslySetInnerHTML={createMarkup(song.lyrics)}
                      />
                    </div>
                    {song.nextTrack && (
                      <div className="next-track-wrap">
                        Next Track:{" "}
                        <NavLink to={`/lyrics/${song.nextTrack.id}`}>
                          <span className="next-track-name">
                            {song.nextTrack.name}
                          </span>
                        </NavLink>
                      </div>
                    )}
                  </React.Fragment>
                ) : (
                  <div className="song-lyrics-msg-wrap">
                    <div>
                      <h1>
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                      </h1>
                      <p>{apiStatus.message}</p>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="song-lyrics-container">
                <div className="song-lyrics-msg-wrap">
                  <div>
                    <div className="song-lyrics-msg">Loading lyrics...</div>
                    <FontAwesomeIcon
                      icon={faCircleNotch}
                      className="fa-spin loader"
                    />
                  </div>
                </div>
              </div>
            )}
            <div
              className={`song-screen ${
                annotationMobDisplay ? "show-mobile" : "hide-mobile"
              }`}
              onClick={(e) => setAnnotationMobDisplay(false)}
            ></div>
            {/*match.isExact && song && song.openingThoughts && (
              <div className="song-annotation-container">
                <Summary song={song} />
              </div>
            )*/}
            {!match.isExact && (
              <div
                className={`song-annotation-container ${
                  annotationMobDisplay ? "show-mobile" : "hide-mobile"
                }`}
              >
                <Switch>
                  <Route
                    path={`${match.url}/:refID`}
                    render={(props) => (
                      <Reference
                        {...props}
                        setAnnotationMobDisplay={setAnnotationMobDisplay}
                      />
                    )}
                  />
                </Switch>
              </div>
            )}

            <div
              className={`discography-screen ${
                discogMobDisplay ? "show-panel" : "hide-panel"
              }`}
              onClick={(e) => setDiscogMobDisplay(false)}
            ></div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Lyrics;
