import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import config from "../config/Config";

const DiscographyPanel = () => {
  const [albumExpand, setAlbumExpand] = useState("");
  const [apiStatus, setAPIStatus] = useState({
    error: false,
    loaded: false,
    message: "",
  });
  const [albums, setAlbums] = useState();

  const handleAlbumExpand = (event, albumName) => {
    setAlbumExpand({
      ...albumExpand,
      [albumName]: !albumExpand[albumName],
    });
  };

  useEffect(() => {
    setAPIStatus({
      error: false,
      loaded: false,
      message: "",
    });

    fetch(`${config.api_root}/albums`)
      .then((response) => response.json())
      .then(
        (result) => {
          setAlbums(result);
          setAPIStatus({
            error: false,
            loaded: true,
            message: "",
          });
        },
        (error) => {
          setAPIStatus({
            error: true,
            loaded: true,
            message: "There was an error fetching the albums: " + error,
          });
        }
      );
  }, []);

  return (
    <React.Fragment>
      {apiStatus.loaded ? (
        apiStatus.error ? (
          <div className="discography-panel-msg-container">
            <div className="discography-container-msg-wrap">
              <div>
                <FontAwesomeIcon icon={faExclamationTriangle} />
                <div className="discography-container-msg">
                  {apiStatus.message}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="discography-panel-container">
            {albums.map((album) => {
              return (
                <div className="lyrics-album-wrap" key={album.id}>
                  <div className="lyrics-album-info-wrap">
                    <div
                      className="lyrics-album-img"
                      style={{
                        backgroundImage: `url("https://cdn.surlydigital.com/cah/assets/albums/${album.imageUrl}")`,
                      }}
                      onClick={(e) => handleAlbumExpand(e, album.id)}
                    ></div>
                    <div className="lyrics-album-name">{album.name}</div>
                  </div>
                  <div
                    className={`lyrics-album-track-container ${
                      albumExpand[album.id] ? "expand" : ""
                    }`}
                  >
                    <div className="lyrics-album-track-wrap">
                      {album.tracks.map((track) => {
                        return (
                          <NavLink
                            to={`/lyrics/${track.trackId}`}
                            key={track.trackId}
                            className="lyrics-album-track-link"
                          >
                            <div className="lyrics-album-track-name">
                              {track.trackName}
                              {track.trackBonus && (
                                <span className="track-bonus">*</span>
                              )}
                            </div>
                          </NavLink>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )
      ) : (
        <div className="discography-panel-msg-container">
          <div className="discography-container-msg-wrap">
            <div>
              <FontAwesomeIcon
                icon={faCircleNotch}
                className="fa-spin loader"
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default DiscographyPanel;
