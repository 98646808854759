import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-inner">
        <div className="copyright">
          © {new Date().getFullYear()} Surly Digital
        </div>
        <div className="disclaimer">
          <i>Clicks and Hisses</i> is not an official The Hold Steady website
          and is not sponsored by, or affiliated with, The Hold Steady. To visit
          the official The Hold Steady website, please click{" "}
          <a href="https://theholdsteady.net" target="_blank" rel="noreferrer">
            here
          </a>
          . If you have any questions or comments about this Legal Disclaimer or
          the practices of this site, please contact the webmaster at{" "}
          <a href="mailto:contact@clicksandhisses.com">
            contact@clicksandhisses.com
          </a>
          .
        </div>
        <div className="flaticon-msg">
          Icons made by Zlatko Najdenovski from{" "}
          <a href="https://flaticon.com" target="_blank" rel="noreferrer">
            www.flaticon.com
          </a>{" "}
          are licensed by CC 3.0 BY.
        </div>
        <div className="powered-up-msg">
          Site powered by{" "}
          <a
            href="https://www.linkedin.com/in/matthew-hess-67538a4/"
            target="_blank"
            rel="noreferrer"
          >
            some new upper drug
          </a>
          .
        </div>
      </div>
    </div>
  );
};

export default Footer;
