import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faMapMarkerAlt,
  faTimesCircle,
  faChevronDown,
  faChevronUp,
  faBinoculars,
} from "@fortawesome/free-solid-svg-icons";

const zoomOptions = [
  {
    id: "united-states",
    name: "United States",
    value: '{"center":{"lat":39.828155,"lng":-98.586045},"zoom":5}',
  },
  {
    id: "twin-cities",
    name: "Twin Cities",
    value: '{"center":{"lat":44.977753,"lng":-93.265011},"zoom":11}',
  },
  {
    id: "new-england",
    name: "New England",
    value: '{"center":{"lat":42.360082,"lng":-71.05888},"zoom":8}',
  },
  {
    id: "la-metro",
    name: "LA Metro",
    value: '{"center":{"lat":34.052234,"lng":-118.243685},"zoom":9}',
  },
  {
    id: "front-range",
    name: "Front Range",
    value: '{"center":{"lat":40.58526,"lng":-105.084423},"zoom":8}',
  },
  {
    id: "global",
    name: "Global",
    value: '{"center":{"lat":14.5994,"lng":-28.6731},"zoom":2}',
  },
];

const MapControls = ({
  refs,
  apiStatus,
  filterText,
  setFilterText,
  refSelection,
  setRefSelection,
  pinsExpanded,
  setPinsExpanded,
  setMapAttr,
}) => {
  return (
    <div className="map-custom-controls">
      <div className="map-control-search-container">
        <div className="map-control-search-wrapper">
          <FontAwesomeIcon icon={faSearch} className="input-icon" />
          <FontAwesomeIcon
            icon={faTimesCircle}
            className={`btn-input-close ${
              filterText === "" ? "disabled" : "enabled"
            }`}
            onClick={(e) => {
              setFilterText("");
              setRefSelection("");
            }}
          />
          <input
            type="text"
            name="annotationFilter"
            placeholder="Search"
            onChange={(e) => setFilterText(e.target.value)}
            value={filterText}
          />
        </div>
        <div className="map-filter-results-container">
          {filterText === "" && (
            <div
              className="map-filter-results-action"
              onClick={(e) => setPinsExpanded(!pinsExpanded)}
            >
              {pinsExpanded ? (
                <FontAwesomeIcon icon={faChevronUp} />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} />
              )}
            </div>
          )}
          <div
            className={`map-filter-results-wrap ${
              filterText !== "" || pinsExpanded ? "show" : "hide"
            }`}
          >
            {apiStatus.loaded &&
              (filterText !== "" || pinsExpanded) &&
              refs
                .filter((ref) =>
                  ref.name.toLowerCase().includes(filterText.toLowerCase())
                )
                .map((ref) => (
                  <div
                    key={ref.id}
                    className={`map-filter-result ${
                      refSelection === ref.id ? "active" : ""
                    }`}
                    onClick={(e) => {
                      setRefSelection(ref.id);
                      setFilterText(ref.name);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      className="result-icon"
                    />
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          filterText === ""
                            ? ref.name
                            : ref.name.replace(
                                new RegExp(filterText, "gi"),
                                (match) => `<mark>${match}</mark>`
                              ),
                      }}
                    />
                  </div>
                ))}
          </div>
        </div>
      </div>
      <div className="map-control-zoom-container">
        <div className="map-control-zoom-wrap">
          <FontAwesomeIcon icon={faBinoculars} className="select-icon" />
          <select onChange={(e) => setMapAttr(JSON.parse(e.target.value))}>
            {zoomOptions.map((zoomOption) => (
              <option key={zoomOption.id} value={zoomOption.value}>
                {zoomOption.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default MapControls;
