import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faExclamationTriangle,
  faCaretRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import config from "../config/Config";

const Annotation = ({ setAnnotationMobDisplay, ...props }) => {
  let { refID } = props.match.params;
  const [apiStatus, setAPIStatus] = useState({
    error: false,
    loaded: false,
    message: "",
  });
  const [ref, setRef] = useState();
  const [imageLoad, setImageLoad] = useState(false);

  useEffect(() => {
    setAPIStatus({
      error: false,
      loaded: false,
      message: "",
    });

    fetch(`${config.api_root}/refs/${refID}`)
      .then((response) => response.json())
      .then(
        (result) => {
          setRef(result);
          setAPIStatus({
            error: false,
            loaded: true,
            message: "",
          });
        },
        (error) => {
          setAPIStatus({
            error: true,
            loaded: true,
            message: "There was an error fetching the annotation: " + error,
          });
        }
      );
  }, [refID]);

  const createMarkup = (value) => {
    return { __html: value };
  };

  return (
    <div className="annotation-container">
      <div
        className="annotation-minimize-wrap"
        onClick={(e) => setAnnotationMobDisplay(false)}
      >
        <FontAwesomeIcon icon={faChevronDown} />
      </div>
      {apiStatus.loaded ? (
        apiStatus.error ? (
          <div className="annotation-msg-wrap">
            <div className="annotation-msg-error-wrap">
              <h1>
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </h1>
              <p>{apiStatus.message}</p>
            </div>
          </div>
        ) : (
          <div className="annotation-wrap">
            <div className="annotation-title">{ref.name}</div>
            <div className="annotation-attr-wrap">
              <div className="annotation-type-wrap">
                <div className="annotation-type">
                  <div className="type-icon-sm">
                    <img
                      src={`https://cdn.surlydigital.com/cah/assets/icons/black/icon-${ref.type.id}.png`}
                      alt={ref.type.name}
                    />
                  </div>
                  {ref.type.name}
                </div>
              </div>
              <div className="annotation-attr-divider">&#8226;</div>
              <div className="annotation-author-wrap">
                <div className="annotation-author">
                  <img
                    className="type-icon-sm"
                    src={`https://cdn.surlydigital.com/cah/assets/other/avatar-${ref.author.id}.jpg`}
                    alt={ref.author.name}
                  />
                  {ref.author.name}
                </div>
              </div>
              {ref.timsListeningParty && (
                <React.Fragment>
                  <div className="annotation-attr-divider">&#8226;</div>
                  <div className="annotation-type">
                    <div className="type-icon-sm">
                      <img
                        src={`https://cdn.surlydigital.com/cah/assets/other/avatar-tim.jpg`}
                        alt="Tim's Twitter Listening Party"
                      />
                    </div>
                    Tim
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className={`annotation-img-wrap ${imageLoad ? "loaded" : ""}`}>
              <div className="annotation-img-loading"></div>
              <img
                src={`https://cdn.surlydigital.com/cah/assets/refs/${ref.imageUrl}`}
                onLoad={(e) => setImageLoad(true)}
                alt={ref.name}
              />
            </div>
            <div
              className="annotation-description"
              dangerouslySetInnerHTML={createMarkup(ref.description)}
            ></div>
            {ref.timsListeningParty && (
              <div className="tim-wrap">
                <div className="annotation-type">
                  <div className="type-icon-sm">
                    <img
                      src={`https://cdn.surlydigital.com/cah/assets/other/avatar-tim.jpg`}
                      alt="Tim's Twitter Listening Party"
                    />
                  </div>
                  Tim's Twitter Listening Party Update
                </div>
                <div
                  className="annotation-description"
                  dangerouslySetInnerHTML={createMarkup(ref.timsListeningParty)}
                ></div>
              </div>
            )}
            <div className="annotation-details">
              <NavLink to={`/details/${ref.id}`} className="override">
                Details <FontAwesomeIcon icon={faCaretRight} />
              </NavLink>
            </div>
          </div>
        )
      ) : (
        <div className="annotation-msg-wrap">
          <FontAwesomeIcon icon={faCircleNotch} className="fa-spin loader" />
        </div>
      )}
    </div>
  );
};

export default Annotation;
