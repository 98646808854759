import React from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

const MapMarker = ({ annotation, refSelection, setRefSelection }) => {
  const createMarkup = (value) => {
    return { __html: value };
  };

  return (
    <Marker
      key={annotation.id}
      position={{
        lat: annotation.mapInfo.latitude,
        lng: annotation.mapInfo.longitude,
      }}
      onClick={(e) => setRefSelection(annotation.id)}
    >
      {refSelection === annotation.id && (
        <InfoWindow
          position={{
            lat: annotation.mapInfo.latitude,
            lng: annotation.mapInfo.longitude,
          }}
          onCloseClick={(e) => setRefSelection("")}
          options={{
            maxWidth: "340",
            outerHeight: "400",
          }}
        >
          <div className="map-info-window-wrap">
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="btn-map-info-close"
              onClick={(e) => {
                setRefSelection("");
              }}
            />
            <div
              className="map-info-window-img"
              style={{
                backgroundImage: `url(
                  https://cdn.surlydigital.com/cah/assets/refs/${annotation.imageUrl}
                )`,
              }}
            ></div>
            <div className="map-info-window-label">{annotation.name}</div>
            <div
              className="map-info-window-desc"
              dangerouslySetInnerHTML={createMarkup(annotation.description)}
            ></div>
            <div className="map-info-window-details">
              <NavLink to={`/details/${annotation.id}`} className="override">
                Details <FontAwesomeIcon icon={faCaretRight} />
              </NavLink>
            </div>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};

export default MapMarker;
