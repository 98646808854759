import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { pageTransition, pageVariants } from "../config/PageAnimations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faTimesCircle,
  faCircleNotch,
  faExclamationTriangle,
  faChevronUp,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AnimatedNumber from "animated-number-react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import config from "../config/Config";

const Search = () => {
  const [apiStatus, setAPIStatus] = useState({
    error: false,
    loaded: false,
    message: "",
  });
  const [apiStatusAuthors, setAPIStatusAuthors] = useState({
    error: false,
    loaded: false,
    message: "",
  });
  const [apiStatusCategories, setAPIStatusCategories] = useState({
    error: false,
    loaded: false,
    message: "",
  });
  const [refs, setRefs] = useState();
  const [authors, setAuthors] = useState([]);
  const [categories, setCatgories] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [filterAnnotator, setFilterAnnotator] = useState("");
  const [filterType, setFilterType] = useState("");
  const [mobFilterDisplay, setMobFilterDisplay] = useState(true);
  const refIncremement = 20;
  const [refsToShow, setRefsToShow] = useState(refIncremement);

  useEffect(() => {
    setAPIStatus({
      error: false,
      loaded: false,
      message: "",
    });

    fetch(`${config.api_root}/refs/`)
      .then((response) => response.json())
      .then(
        (result) => {
          setRefs(result);
          setAPIStatus({
            error: false,
            loaded: true,
            message: "",
          });
        },
        (error) => {
          setAPIStatus({
            error: true,
            loaded: true,
            message: "There was an error fetching the annotation: " + error,
          });
        }
      );

    fetch(`${config.api_root}/authors/`)
      .then((response) => response.json())
      .then(
        (result) => {
          setAuthors(result);
          setAPIStatusAuthors({
            error: false,
            loaded: true,
            message: "",
          });
        },
        (error) => {
          setAPIStatusAuthors({
            error: true,
            loaded: true,
            message: "There was an error fetching the authors: " + error,
          });
        }
      );

    fetch(`${config.api_root}/categories/`)
      .then((response) => response.json())
      .then(
        (result) => {
          setCatgories(result);
          setAPIStatusCategories({
            error: false,
            loaded: true,
            message: "",
          });
        },
        (error) => {
          setAPIStatusCategories({
            error: true,
            loaded: true,
            message: "There was an error fetching the catgories: " + error,
          });
        }
      );
  }, []);

  const createMarkup = (value) => {
    return { __html: value };
  };

  const handleFilterRefs = (ref) => {
    return (
      ref.name.toLowerCase().includes(filterText.toLowerCase()) &&
      ref.author.id.toLowerCase().includes(filterAnnotator.toLowerCase()) &&
      ref.type.id.toLowerCase().includes(filterType.toLowerCase())
    );
  };

  useBottomScrollListener(() => setRefsToShow(refsToShow + refIncremement));

  useEffect(() => {
    setRefsToShow(refIncremement);
    window.scrollTo(0, 0);
  }, [filterText, filterAnnotator, filterType]);

  return (
    <motion.div
      className="main-content-container"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Helmet>
        <title>Search | Clicks and Hisses</title>
      </Helmet>
      <div className="main-content-outer flex-start">
        <div className="main-content-inner">
          <div className="search-container">
            <div className="search-filters-container">
              <div
                className={`search-controls-container ${
                  mobFilterDisplay ? "show-controls" : "hide-controls"
                }`}
              >
                <div className="search-control-container">
                  <div className="search-control-wrapper">
                    <FontAwesomeIcon icon={faSearch} className="input-icon" />
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className={`btn-input-close ${
                        filterText === "" ? "disabled" : "enabled"
                      }`}
                      onClick={(e) => setFilterText("")}
                    />
                    <input
                      type="text"
                      name="annotationFilter"
                      placeholder="Filter by name."
                      onChange={(e) => setFilterText(e.target.value)}
                      value={filterText}
                    />
                  </div>
                </div>
                <div className="search-control-container">
                  <div className="search-control-wrapper">
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className={`btn-input-close ${
                        filterAnnotator === "" ? "disabled" : "enabled"
                      }`}
                      onClick={(e) => setFilterAnnotator("")}
                    />
                    <select
                      onChange={(e) => setFilterAnnotator(e.target.value)}
                      value={filterAnnotator || "init"}
                    >
                      <option value="init" disabled>
                        Filter by annotator.
                      </option>
                      {apiStatusAuthors.message ? (
                        <option disabled>{apiStatusAuthors.message}</option>
                      ) : (
                        authors.map((annotator) => (
                          <option value={annotator.id} key={annotator.id}>
                            {annotator.name}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                </div>
                <div className="search-control-container">
                  <div className="search-control-wrapper">
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className={`btn-input-close ${
                        filterType === "" ? "disabled" : "enabled"
                      }`}
                      onClick={(e) => setFilterType("")}
                    />
                    <select
                      onChange={(e) => setFilterType(e.target.value)}
                      value={filterType || "init"}
                    >
                      <option value="init" disabled>
                        Filter by type.
                      </option>
                      {apiStatusCategories.message ? (
                        <option disabled>{apiStatusCategories.message}</option>
                      ) : (
                        categories.map((type) => (
                          <option value={type.id} key={type.id}>
                            {type.name}
                          </option>
                        ))
                      )}
                      {}
                    </select>
                  </div>
                </div>
              </div>
              {refs && (
                <div className="search-total-wrap">
                  <AnimatedNumber
                    value={refs.filter(handleFilterRefs).length}
                    formatValue={(value) => value.toFixed(0)}
                    className="search-total-number"
                  />{" "}
                  annotation
                  {refs.filter(handleFilterRefs).length === 1 ? "" : "s"} found.
                </div>
              )}
              <div
                className="search-filters-minimize-wrap"
                onClick={(e) => setMobFilterDisplay(!mobFilterDisplay)}
              >
                <FontAwesomeIcon
                  icon={mobFilterDisplay ? faChevronUp : faFilter}
                />
              </div>
            </div>
            <div className="search-results-container">
              {apiStatus.loaded ? (
                apiStatus.error ? (
                  <div className="search-msg-wrap">
                    <h1>
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    </h1>
                    <div className="search-msg">{apiStatus.message}</div>
                  </div>
                ) : (
                  <TransitionGroup className="search-results-list">
                    {refs
                      .filter(handleFilterRefs)
                      .slice(0, refsToShow)
                      .map((ref) => (
                        <CSSTransition
                          key={ref.id}
                          timeout={500}
                          classNames="search-result-item"
                        >
                          <div className="search-result-container">
                            <NavLink
                              to={`/details/${ref.id}`}
                              className="override"
                            >
                              <div
                                className="search-result-img"
                                style={{
                                  backgroundImage: `url(https://cdn.surlydigital.com/cah/assets/refs/${ref.imageUrl})`,
                                  backgroundPosition: "center",
                                  backgroundSize: "cover",
                                }}
                              ></div>
                              <div className="search-result-inner">
                                <div className="annotation-title">
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        filterText === ""
                                          ? ref.name
                                          : ref.name.replace(
                                              new RegExp(filterText, "gi"),
                                              (match) => `<mark>${match}</mark>`
                                            ),
                                    }}
                                  />
                                </div>
                                <div className="annotation-attr-wrap">
                                  <div className="annotation-type-wrap">
                                    <div className="annotation-type">
                                      <div className="type-icon-sm">
                                        <img
                                          src={`https://cdn.surlydigital.com/cah/assets/icons/black/icon-${ref.type.id}.png`}
                                          alt={ref.type.name}
                                        />
                                      </div>
                                      {ref.type.name}
                                    </div>
                                  </div>
                                  <div className="annotation-attr-divider">
                                    &#8226;
                                  </div>
                                  <div className="annotation-author-wrap">
                                    <div className="annotation-author">
                                      <img
                                        className="type-icon-sm"
                                        src={`https://cdn.surlydigital.com/cah/assets/other/avatar-${ref.author.id}.jpg`}
                                        alt={ref.author.name}
                                      />
                                      {ref.author.name}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="annotation-description"
                                  dangerouslySetInnerHTML={createMarkup(
                                    ref.description
                                  )}
                                ></div>
                              </div>
                            </NavLink>
                          </div>
                        </CSSTransition>
                      ))}
                  </TransitionGroup>
                )
              ) : (
                <div className="search-msg-wrap">
                  <div className="search-msg">Loading search results...</div>
                  <FontAwesomeIcon
                    icon={faCircleNotch}
                    className="fa-spin loader"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Search;
