import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { pageTransition, pageVariants } from "../config/PageAnimations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretRight,
  faCircleNotch,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { mapOptions } from "../config/MapConfig";
import { Helmet } from "react-helmet";
import config from "../config/Config";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const Details = ({ ...props }) => {
  let { detailID } = props.match.params;
  const [apiStatus, setAPIStatus] = useState({
    error: false,
    loaded: false,
    message: "",
  });
  const [ref, setRef] = useState();

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyAeM7YDzk5sTnayeT0HWa6qamOQwcOubOI",
  });

  useEffect(() => {
    fetch(`${config.api_root}/refs/${detailID}`)
      .then((response) => response.json())
      .then(
        (result) => {
          setRef(result);
          setAPIStatus({
            error: false,
            loaded: true,
            message: "",
          });
        },
        (error) => {
          setAPIStatus({
            error: true,
            loaded: true,
            message: "There was an error fetching the reference: " + error,
          });
        }
      );
  }, [detailID]);

  const createMarkup = (value) => {
    return { __html: value };
  };

  return (
    <motion.div
      className="main-content-container"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Helmet>
        <title>Clicks and Hisses | {ref ? ref.name : "Details"}</title>
      </Helmet>
      <div className="main-content-outer flex-start">
        <div className="main-content-inner">
          <div className="details-container">
            <div className="detail-hero-wrap">
              <div className="detail-hero-wrap-bg-wrap">
                <div
                  className="detail-hero-wrap-bg"
                  style={
                    apiStatus.loaded && !apiStatus.error
                      ? {
                          backgroundImage: `url(
                      https://cdn.surlydigital.com/cah/assets/refs/${ref.imageUrl}
                    )`,
                        }
                      : {
                          backgroundColor: "#eee",
                        }
                  }
                ></div>
              </div>
              <div
                className="detail-img"
                style={
                  apiStatus.loaded && !apiStatus.error
                    ? {
                        backgroundImage: `url(
                    https://cdn.surlydigital.com/cah/assets/refs/${ref.imageUrl}
                  )`,
                      }
                    : {
                        backgroundColor: "#b3b3b3",
                      }
                }
              ></div>
            </div>
            {apiStatus.loaded ? (
              apiStatus.error ? (
                <div className="detail-info-container">
                  <div className="detail-loading-msg-wrap">
                    <h1>
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    </h1>
                    <div className="detail-msg">{apiStatus.message}</div>
                  </div>
                </div>
              ) : (
                <div className="detail-info-container">
                  <div className="detail-title">
                    <h1>{ref.name}</h1>
                    <div className="annotation-attr-wrap">
                      <div className="annotation-type-wrap">
                        <div className="annotation-type">
                          <div className="type-icon-sm">
                            <img
                              src={`https://cdn.surlydigital.com/cah/assets/icons/black/icon-${ref.type.id}.png`}
                              alt={ref.name}
                            />
                          </div>
                          {ref.type.name}
                        </div>
                      </div>
                      <div className="annotation-attr-divider">&#8226;</div>
                      <div className="annotation-author-wrap">
                        <div className="annotation-author">
                          <img
                            className="type-icon-sm"
                            src={`https://cdn.surlydigital.com/cah/assets/other/avatar-${ref.author.id}.jpg`}
                            alt={ref.author.name}
                          />
                          {ref.author.name}
                        </div>
                      </div>
                      {ref.timsListeningParty && (
                        <React.Fragment>
                          <div className="annotation-attr-divider">&#8226;</div>
                          <div className="annotation-type">
                            <div className="type-icon-sm">
                              <img
                                src={`https://cdn.surlydigital.com/cah/assets/other/avatar-tim.jpg`}
                                alt="Tim's Twitter Listening Party"
                              />
                            </div>
                            Tim
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                  <div className="detail-section">
                    <div className="detail-section-heading">Description</div>
                    <div
                      className="detail-section-desc"
                      dangerouslySetInnerHTML={createMarkup(ref.description)}
                    ></div>
                    {ref.timsListeningParty && (
                      <div className="tim-wrap">
                        <div className="annotation-type">
                          <div className="type-icon-sm">
                            <img
                              src={`https://cdn.surlydigital.com/cah/assets/other/avatar-tim.jpg`}
                              alt="Tim's Twitter Listening Party"
                            />
                          </div>
                          Tim's Twitter Listening Party Update
                        </div>
                        <div
                          className="annotation-description"
                          dangerouslySetInnerHTML={createMarkup(
                            ref.timsListeningParty
                          )}
                        ></div>
                      </div>
                    )}
                  </div>
                  {ref.mapInfo && (
                    <div className="detail-section">
                      <div className="detail-section-heading">Map</div>
                      <div className="map-detail-container">
                        {isLoaded && (
                          <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={{
                              lat: ref.mapInfo.latitude,
                              lng: ref.mapInfo.longitude,
                            }}
                            zoom={ref.mapInfo.zoom}
                            options={mapOptions}
                          >
                            <Marker
                              key={ref.id}
                              position={{
                                lat: ref.mapInfo.latitude,
                                lng: ref.mapInfo.longitude,
                              }}
                            >
                              <InfoWindow
                                position={{
                                  lat: ref.mapInfo.latitude,
                                  lng: ref.mapInfo.longitude,
                                }}
                                options={{
                                  minWidth: "200",
                                  maxWidth: "340",
                                  outerHeight: "400",
                                }}
                              >
                                <div className="map-info-window-wrap">
                                  <div className="map-info-window-label">
                                    {ref.name}
                                  </div>
                                  <div className="map-info-window-details">
                                    <NavLink
                                      to={`/map/${ref.id}`}
                                      className="override"
                                    >
                                      Open Map{" "}
                                      <FontAwesomeIcon icon={faCaretRight} />
                                    </NavLink>
                                  </div>
                                </div>
                              </InfoWindow>
                            </Marker>
                          </GoogleMap>
                        )}
                        {loadError && <div>Could not load map!</div>}
                      </div>
                    </div>
                  )}
                  {ref.mentions && (
                    <div className="detail-section">
                      <div className="detail-section-heading">Mentions</div>
                      {ref.mentions.map((mention) => (
                        <div className="mentions-container" key={mention.id}>
                          <NavLink
                            to={`/lyrics/${mention.id}/${ref.id}`}
                            className="override"
                          >
                            <div className="mention-container-med">
                              <div
                                className="mention-img"
                                style={{
                                  backgroundImage: `url(https://cdn.surlydigital.com/cah/assets/albums/${mention.imageUrl})`,
                                }}
                              ></div>
                              <div className="mention-song-container">
                                <div className="mention-song-title">
                                  {mention.name}
                                </div>
                              </div>
                            </div>
                          </NavLink>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )
            ) : (
              <div className="detail-info-container">
                <div className="detail-loading-msg-wrap">
                  <div className="detail-msg">Loading...</div>
                  <FontAwesomeIcon
                    icon={faCircleNotch}
                    className="fa-spin loader"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Details;
