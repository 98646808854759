import React from "react";
import { motion } from "framer-motion";
import { pageTransition, pageVariants } from "../config/PageAnimations";
import Footer from "../utils/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <motion.div
      className="main-content-container"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Helmet>
        <title>About | Clicks and Hisses</title>
      </Helmet>
      <div className="main-content-outer flex-start">
        <div className="main-content-inner">
          <div className="about-container">
            <div className="about-intro-wrap">
              <h1>I always dream about a unified scene.</h1>
              <p>
                It has become somewhat of a cliché to refer to The Hold Steady
                as "the greatest bar band in America." While it is true that
                their music is perfect for the loose but tasteful dive bar
                crowd, the band has a not-so-secret weapon: songwriter Craig
                Finn's lyrics. Dense, literary, strewn with callbacks, and born
                out of the mythology of Minnesota's Twin Cities, they tell
                sprawling stories of parties and churches, addiction and
                redemption, sinners and salvation. They are part of what
                elevates The Hold Steady far, far beyond your typical bar band.
                <br></br>
                <br></br>
                Our mission here is to build a comprehensive, interactive guide
                to The Hold Steady discography. A unified scene, if you will. We
                want to walk you through the lyric sheet. Take you to the
                landmarks where everything happens. But mostly, we hope to offer
                you an explanation of what it all might mean (to us). This
                project isn't meant to be definitive.{" "}
                <a
                  href="http://holdsteady.wikia.com/wiki/Main_Page"
                  target="_blank"
                  rel="noreferrer"
                >
                  The Hold Steady Wiki
                </a>
                ,{" "}
                <a
                  href="https://genius.com/artists/The-hold-steady"
                  target="_blank"
                  rel="noreferrer"
                >
                  Genius
                </a>
                , and{" "}
                <a
                  href="http://theholdsteady.proboards.com/board/3/hold-steady"
                  target="_blank"
                  rel="noreferrer"
                >
                  The Unified Scene
                </a>{" "}
                are all outstanding resources for learning more about the songs
                and their attendant narratives. In the words of André 3000:
                "This is just [our] interpretation ... of the situation."
                <br></br>
                <br></br>
                Craig once penned the lyric, "everyone's a critic and most
                people are DJs." In interviews, he has explained his frustration
                with the subset of DJs who strictly play other's music and pass
                it off as their own art. Without exposing one's own nervous
                personal creations and baring all to the clustered-up masses, a
                speaking mind in any medium cannot take the risky leap from
                critic to artist. So here is <i>Clicks and Hisses</i> for your
                amusement and involvement.
                <br></br>
                <br></br>
                Stay Positive.
              </p>
            </div>
            <div className="about-contact-wrap">
              <h1>Contact Us</h1>
              <p>
                Impressed? Confused? Aroused? Give us your feedback.
                <br></br>
                <br></br>
                Follow our social media accounts for the latest updates on the
                site, including features, improvements, and new content.
              </p>
              <div className="contact-link-container">
                <div className="contact-link-wrap">
                  <div className="contact-icon-wrap">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="contact-icon"
                    />
                  </div>
                  <a
                    href="mailto:contact@clicksandhisses.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    contact@clicksandhisses.com
                  </a>
                </div>
                <div className="contact-link-wrap">
                  <div className="contact-icon-wrap twitter">
                    <FontAwesomeIcon
                      icon={faTwitter}
                      className="contact-icon "
                    />
                  </div>
                  <a
                    href="https://twitter.com/clicks_hisses"
                    target="_blank"
                    rel="noreferrer"
                  >
                    @clicks_hisses
                  </a>
                </div>
                <div className="contact-link-wrap">
                  <div className="contact-icon-wrap facebook">
                    <FontAwesomeIcon
                      icon={faFacebookF}
                      className="contact-icon"
                    />
                  </div>
                  <a
                    href="https://www.facebook.com/clicksandhisses"
                    target="_blank"
                    rel="noreferrer"
                  >
                    @clicksandhisses
                  </a>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </motion.div>
  );
};

export default About;
