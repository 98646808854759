import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { pageTransition, pageVariants } from "../config/PageAnimations";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRecordVinyl,
  faMapMarkerAlt,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

const Index = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <motion.div
      className="main-content-container"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Helmet>
        <title>Home | Clicks and Hisses</title>
      </Helmet>
      <div className="main-content-outer">
        <div className="main-content-inner-fullscreen">
          <div
            className={`main-content-outer-bg bg-home ${loaded && "loaded"}`}
          ></div>
          <div className="main-content-outer-overlay"></div>
        </div>
        <div className="main-content-inner">
          <img
            className="home-logo"
            src="https://cdn.surlydigital.com/cah/assets/branding/cah-wordmark.png"
            alt="CAH Logo"
          />
          <p className="color-white">
            An interactive guide to the weird words and sing-along songs of{" "}
            <span className="semi-bold">The Hold Steady</span>.
          </p>
          <div className="home-links-container">
            <NavLink exact to="/lyrics">
              <button>
                <div className="btn-interior">
                  <FontAwesomeIcon icon={faRecordVinyl} className="btn-icon" />
                  Lyrics
                </div>
              </button>
            </NavLink>
            <NavLink to="/map">
              <button>
                <div className="btn-interior">
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="btn-icon" />
                  Map
                </div>
              </button>
            </NavLink>
            <NavLink to="/search">
              <button>
                <div className="btn-interior">
                  <FontAwesomeIcon icon={faSearch} className="btn-icon" />
                  Search
                </div>
              </button>
            </NavLink>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Index;
