import React, { useEffect } from "react";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";
import IndexPage from "./Index";
import LyricsPage from "../lyrics/Lyrics";
import DiscographyPage from "../lyrics/Discography";
import MapPage from "../map/Map";
import SearchPage from "../search/Search";
import AboutPage from "../about/About";
import Nav from "../utils/Nav";
import { AnimatePresence } from "framer-motion";
import DetailsPage from "../details/Details";
import ReactGA from "react-ga";
import config from "../config/Config";

ReactGA.initialize(`UA-${config.google_analytics_ua}`);

const Routes = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location]);

  const evalKey = (key) => {
    // TODO: find a more elegant solution
    let aURL = key.split("/");

    switch (aURL[1]) {
      case "lyrics":
        if (aURL.length > 2) return `/${aURL[1]}/${aURL[2]}`;
        break;
      default:
        return key;
    }
  };

  return (
    <div className="app-container">
      <Nav />
      <AnimatePresence
        exitBeforeEnter
        onExitComplete={() => {
          if (typeof window !== "undefined") {
            window.scrollTo({ top: 0 });
          }
        }}
      >
        <Switch location={location} key={evalKey(location.pathname)}>
          <Route exact path="/" component={IndexPage} />
          <Route path="/index" component={IndexPage} />
          <Route exact path="/lyrics" component={DiscographyPage} />
          <Route
            path="/lyrics.html"
            render={(props) => {
              let songID = new URLSearchParams(location.search).get("song");
              if (songID) return <Redirect to={`/lyrics/${songID}`} />;
              else return <Redirect to="/lyrics" />;
            }}
          />
          <Route
            path="/lyrics/:songID"
            render={(props) => <LyricsPage {...props} />}
          />
          <Route path="/map.html">
            <Redirect to="/map" />;
          </Route>
          <Route exact path="/map" component={MapPage} />
          <Route
            path="/map/:mapID"
            render={(props) => <MapPage {...props} />}
          />
          <Route path="/search.html">
            <Redirect to="/search" />;
          </Route>
          <Route path="/search" component={SearchPage} />
          <Route path="/contact.html">
            <Redirect to="/about" />;
          </Route>
          <Route path="/about" component={AboutPage} />
          <Route
            path="/details.html"
            render={(props) => {
              let refID = new URLSearchParams(location.search).get("ref");
              if (refID) return <Redirect to={`/details/${refID}`} />;
              else return <Redirect to="/search" />;
            }}
          />
          <Route
            path="/details/:detailID"
            render={(props) => <DetailsPage {...props} />}
          />
        </Switch>
      </AnimatePresence>
    </div>
  );
};

export default Routes;
