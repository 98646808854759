import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { pageTransition, pageVariants } from "../config/PageAnimations";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import MapMarker from "./MapMarker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import MapControls from "./MapControls";
import { mapOptions } from "../config/MapConfig";
import { Helmet } from "react-helmet";
import config from "../config/Config";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const Map = ({ ...props }) => {
  const [refs, setRefs] = useState();
  const [apiStatus, setAPIStatus] = useState({
    error: false,
    loaded: false,
    message: "",
  });
  const [filterText, setFilterText] = useState("");
  const [refSelection, setRefSelection] = useState("");
  const [pinsExpanded, setPinsExpanded] = useState(false);
  const [mapAttr, setMapAttr] = useState({
    center: { lat: 39.828155, lng: -98.586045 },
    zoom: 5,
  });
  let { mapID } = props.match.params;

  useEffect(() => {
    if (mapID) setRefSelection(mapID);
  }, [mapID]);

  useEffect(() => {
    setAPIStatus({
      error: false,
      loaded: false,
      message: "",
    });

    fetch(`${config.api_root}/refs/geo`)
      .then((response) => response.json())
      .then(
        (result) => {
          setRefs(result);
          setAPIStatus({
            error: false,
            loaded: true,
            message: "",
          });
        },
        (error) => {
          setAPIStatus({
            error: true,
            loaded: true,
            message: "There was an error fetching the annotations: " + error,
          });
        }
      );
  }, []);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyAeM7YDzk5sTnayeT0HWa6qamOQwcOubOI",
  });

  return (
    <motion.div
      className="main-content-container"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Helmet>
        <title>Map | Clicks and Hisses</title>
      </Helmet>
      <div className="main-content-outer">
        <div className="main-content-inner-fullscreen">
          <div className="map-container">
            {isLoaded && (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={mapAttr.center}
                zoom={mapAttr.zoom}
                options={mapOptions}
              >
                {apiStatus.loaded ? (
                  refs.map((ref) => (
                    <MapMarker
                      annotation={ref}
                      key={ref.id}
                      refSelection={refSelection}
                      setRefSelection={setRefSelection}
                    />
                  ))
                ) : (
                  <div className="map-overlay-message-container">
                    <div className="map-overlay-message-inner">
                      <div className="map-overlay-message">
                        Loading map elements...
                      </div>
                      <FontAwesomeIcon
                        icon={faCircleNotch}
                        className="fa-spin loader"
                      />
                    </div>
                  </div>
                )}
              </GoogleMap>
            )}
            {loadError && <div>Could not load map!</div>}
          </div>
          <MapControls
            refs={refs}
            apiStatus={apiStatus}
            filterText={filterText}
            setFilterText={setFilterText}
            refSelection={refSelection}
            setRefSelection={setRefSelection}
            pinsExpanded={pinsExpanded}
            setPinsExpanded={setPinsExpanded}
            setMapAttr={setMapAttr}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Map;
