const local = {
  env_name: "Local",
  api_root: "https://w18nm4pfz4.execute-api.us-east-2.amazonaws.com/console/",
  google_analytics_ua: "75484836-1", // Does this need to be updated?
};

const dev = {
  env_name: "Dev",
  api_root: "https://w18nm4pfz4.execute-api.us-east-2.amazonaws.com/console/",
  google_analytics_ua: "75484836-1", // Does this need to be updated?
};

const prod = {
  env_name: "Prod",
  api_root: "https://w18nm4pfz4.execute-api.us-east-2.amazonaws.com/console/",
  google_analytics_ua: "75484836-2", // Does this need to be updated?
};

let config = null;

switch (process.env.REACT_APP_ENV.toLowerCase()) {
  case "local":
    config = local;
    break;
  case "dev":
    config = dev;
    break;
  case "prod":
    config = prod;
    break;
  default:
    alert("Invalid config!");
    break;
}

let configExport = {
  ...config,
};

export default configExport;
